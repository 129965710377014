import 'semantic-ui-css/semantic.min.css'
import { Button, Header } from 'semantic-ui-react';
import './App.css';

function App() {
  return (
    <div className="ui container">
      <div className="content">
        <Header as="h1" className="inverted">Vice Chess Engine</Header>
        <div className="spacer"></div>
        <Button size='medium' primary on><a href="/downloads/Vice11.zip">Download v1.1</a></Button>
      </div>
    </div>
  );
}

export default App;
